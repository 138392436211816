import {
  Container,
  FlexContainer,
  HeroBackgroundContainer,
  LineSeparator,
  PromotionalCarousel,
  RegionName,
  SimpleNavigation
} from '@news12/storybook-news'

import React from 'react'

import { CookiesKeys } from '../../config/cookies'
import regions, { Region } from '../../config/regions'
import PIXEL_SCRIPT from '../../scripts/Pixel/NY'
import { PromotedContent, StoryPreview } from '../../types'
import { oneMonthFromNow } from '../../utils/oneMonthFromNow'
import Metadata from '../Metadata'
import StoriesSection from '../StoriesSection/StoriesSection'
import { useSubdomainContext } from '../SubdomainContext/SubdomainContext'
import ThemeProvider from '../ThemeProvider'
import renderHtml, { sanitizedHtml } from '../utilities/renderHTML'
import Banner from './Banner'

import ClientCookies from 'js-cookie'
import Head from 'next/head'
import Script from 'next/script'
import { parse as parser } from 'transformer'

const HERO_IMAGE_URL =
  'https://images-cf.news12static.com/3kqcuzntcg31/3aRkkT4sJPepxmZlM8kFo4/e92c20f64ba2375b49849adcbe2ddb70/bg.png'

export type NYLandingProps = {
  description: string
  banner?: string | null
  topStories: {
    title: string
    list: StoryPreview[]
  }
  content: any
  promotedContent: PromotedContent
  metadata: {
    title: string
    description: string
    thumbnailUrl: string
  }
}

const NYLanding = (props: NYLandingProps) => {
  const { description, banner, topStories, content, promotedContent } = props
  const html = parser(content, false)

  const { domain, path, port, protocol } = useSubdomainContext()

  const regList = Object.entries(regions)
    .filter(([regionId]) => regionId !== Region.DEFAULT && regionId !== Region.NEW_YORK)
    .map(([regionId, region], i) => ({
      key: i,
      href: `${protocol}//${region.name}.${domain}${port ? `:${port}` : ''}${path && path.length > 0 ? path : ''}`,
      name: region.longName,
      id: region.name
    }))

  const sanitizedHtmlStr = sanitizedHtml(html)

  return (
    <>
      <Head>
        <title>{props.metadata.title}</title>
        <link rel='canonical' href={`https://newyork.news12.com${path || ''}`} />
      </Head>
      <Metadata metadata={props.metadata} />
      <Script dangerouslySetInnerHTML={{ __html: PIXEL_SCRIPT }} />
      <SimpleNavigation
        homeUrl='/'
        menuItems={regList}
        regionName={regions[Region.NEW_YORK].longName as RegionName}
        onClick={reg => {
          ClientCookies.set(CookiesKeys.REGION, reg.id, {
            expires: oneMonthFromNow,
            domain
          })
        }}
      />
      {banner && <Banner banner={banner} />}
      <HeroBackgroundContainer bgImage={HERO_IMAGE_URL} />
      <Container className='ny-landing-content'>
        <p className='description'>{description}</p>
        <ThemeProvider theme='dark'>
          <FlexContainer direction='column' margin='2rem auto 1rem' gap='1rem'>
            {renderHtml(sanitizedHtmlStr)}
          </FlexContainer>
        </ThemeProvider>
        {promotedContent?.items?.length > 0 && (
          <Container margin={{ sm: '0', default: '0 -1rem' }} padding={'0'}>
            <PromotionalCarousel items={promotedContent.items} title={promotedContent.title ?? 'News 12 Originals'} />
            <LineSeparator />
          </Container>
        )}
      </Container>
      <Container padding={{ default: 0, sm: '0 3.75rem' }}>
        <StoriesSection category={topStories?.title} stories={topStories?.list} fullWidthBreakpoint='lg' />
      </Container>
    </>
  )
}

export default NYLanding
